import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Province } from '@insig-health/services/province/province.service';
import { PrescriptionDeliveryDialogComponent } from '../../dialogs/prescription-delivery/prescription-delivery-dialog.component';
import { PatientProfile, PatientProfileService } from '@insig-health/services/patient-profile/patient-profile.service';
import { PillwayPharmacyChangeWarningDialogComponent, PillwayPharmacyChangeWarningDialogData } from './pillway-pharmacy-change-warning-dialog/pillway-pharmacy-change-warning-dialog.component';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { EditPatientFormDialogComponent } from '../confirm-booking/edit-patient-form-dialog/edit-patient-form-dialog.component';

export const PILLWAY_PHARMACY_NAME = 'Pillway';
export const PILLWAY_WEST_PHARMACY_FAX = '6046304949';
export const PILLWAY_EAST_PHARMACY_FAX = '8337455929';

export type PharmacyInfoChange = {
  pharmacyName: string;
  pharmacyFax: string;
}

@Component({
  selector: 'insig-booking-prescription-delivery',
  templateUrl: './prescription-delivery.component.html',
  styleUrls: ['./prescription-delivery.component.scss'],
})
export class PrescriptionDeliveryComponent implements OnChanges {
  @Input() patientProfile: PatientProfile | undefined | null;
  @Input() province: Province | undefined;

  public getFreePrescriptionDeliverySelected = false;
  public prescriptionDeliveryPharmacyName = PILLWAY_PHARMACY_NAME;
  private prescriptionDeliveryPharmacyFax: string | undefined;

  constructor(
    private dialog: MatDialog,
    private patientProfileService: PatientProfileService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const provinceChange = changes.province;
    if (provinceChange?.currentValue !== provinceChange?.previousValue && provinceChange?.currentValue !== undefined) {
      this.handleProvinceChanged(provinceChange.currentValue);
    }

    const patientProfileChange = changes.patientProfile;
    if (patientProfileChange?.currentValue !== patientProfileChange?.previousValue && patientProfileChange?.currentValue !== undefined && patientProfileChange?.currentValue !== null) {
      this.handlePatientProfileChanged(patientProfileChange.currentValue);
    }
  }

  private handleProvinceChanged(province: Province): void {
    if ([Province.BC, Province.AB].includes(province)) {
      this.prescriptionDeliveryPharmacyFax = PILLWAY_WEST_PHARMACY_FAX;
    } else {
      this.prescriptionDeliveryPharmacyFax = PILLWAY_EAST_PHARMACY_FAX;
    }
  }

  private handlePatientProfileChanged(patientProfile: PatientProfile): void {
    if (patientProfile.pharmacyName === PILLWAY_PHARMACY_NAME && (patientProfile.pharmacyFax === PILLWAY_WEST_PHARMACY_FAX || patientProfile.pharmacyFax === PILLWAY_EAST_PHARMACY_FAX)) {
      this.getFreePrescriptionDeliverySelected = true;
    } else {
      this.getFreePrescriptionDeliverySelected = false;
    }
  }

  handleFulfilledByClicked(): void {
    this.dialog.open(PrescriptionDeliveryDialogComponent, {
      autoFocus: 'button',
    });
  }

  async handleGetFreePrescriptionDeliveryClicked(getFreePrescriptionDeliverySelected: boolean): Promise<void> {
    if (!this.patientProfile || await this.canChangePharmacy(getFreePrescriptionDeliverySelected) === false) {
      return;
    }

    if (getFreePrescriptionDeliverySelected) {
      await this.editPharmacyInfoInPatientProfile(this.patientProfile);
    } else {
      await this.patientProfileService.setCurrentUserPatientProfile({
        pharmacyName: this.prescriptionDeliveryPharmacyName,
        pharmacyFax: this.prescriptionDeliveryPharmacyFax,
      });
    }

    this.handlePatientProfileChanged(this.patientProfile);
  }

  async canChangePharmacy(getFreePrescriptionDeliverySelected: boolean): Promise<boolean> {
    const pillwayChangeWarningDialogRef = this.dialog.open<PillwayPharmacyChangeWarningDialogComponent, PillwayPharmacyChangeWarningDialogData>(PillwayPharmacyChangeWarningDialogComponent,
      {
        data: { isPillwaySelected: getFreePrescriptionDeliverySelected },
        ...PillwayPharmacyChangeWarningDialogComponent.DEFAULT_DIALOG_CONFIG,
      },
    );

    return firstValueFrom(pillwayChangeWarningDialogRef.afterClosed());
  }

  async editPharmacyInfoInPatientProfile(patientProfile: PatientProfile): Promise<void> {
    const dialogRef = this.dialog.open(EditPatientFormDialogComponent, {
      data: {
        patientProfile: patientProfile,
      },
      maxWidth: EditPatientFormDialogComponent.DIALOG_MAX_WIDTH,
      width: EditPatientFormDialogComponent.DIALOG_WIDTH,
      autoFocus: '#pharmacyInfoForm',
    });

    await lastValueFrom(dialogRef.afterClosed());
  }
}
